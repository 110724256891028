<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5 flex-column justify-content-start">
        <div class="row w-100 px-5 mb-4">
          <div class="col-3">
            <b-link class="logo-login">
              <b-img :src="imgLogo" alt="logo" />
            </b-link>
          </div>
        </div>

        <!-- Left Text-->
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5 h-100">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-light" title-tag="h2">
            {{ $t("Welcome to IntellySCALE!") }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("Please sign-in to your account and start the adventure") }}
          </b-card-text>
          <b-card-text class="mb-2">
              <b-alert v-model="validate" variant="danger" dismissible class="p-2">
                {{ validation }}
              </b-alert>
            </b-card-text>


            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- email -->
                <b-form-group :label="$t('Email')" label-for="login-email">
                  <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                    <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                      name="login-email" placeholder="john@example.com" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t("Password:") }}</label>
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>{{ $t("Forgot Password?") }}</small>
                    </b-link>
                  </div>
                  <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                        class="form-control-merge" :type="passwordFieldType" name="login-password"
                        placeholder="Password" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1" class="mt-1">
                    {{ $t("Remember Me") }}
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button type="submit" variant="success" block :disabled="invalid" class="mt-2">
                  {{ $t("Sign in") }}
                </b-button>
              </b-form>
            </validation-observer>
            <b-button v-if="registerFormEnabled === 'true'" type="button" variant="primary" block class="mt-2" :to="{ name: 'auth-signup' }">
              {{ $t("Sign up") }}
            </b-button>

        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import useJwt from "@/auth/jwt/useJwt";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: process.env.VUE_APP_DEFAULT_USER_PASSWORD,
      userEmail: process.env.VUE_APP_DEFAULT_USER_EMAIL,
      sideImg: require('@/assets/images/pages/login-v4.svg'),
      logoImg: require('@/assets/images/logo/intellyscale.svg'),
      validation:"",
      validate:false,

      // validation rules
      required,
      email,
      registerFormEnabled: process.env.VUE_APP_ENABLE_REGISTER,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v4-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoImg = require('@/assets/images/logo/intellyscale-negativ.svg')
        return this.logoImg
      }
      return this.logoImg
    },
  },

  mounted() {
    this.registerFormEnabled = process.env.VUE_APP_ENABLE_REGISTER;
    console.debug(this.registerFormEnabled)
  },

  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              const userData = response.data;
              if (userData?.client?.logo?.url) {
                localStorage.setItem("company_logo",userData?.client?.logo?.url)
                this.$store.dispatch('GET_COMPANY_LOGO', userData?.client?.logo?.url)
              }
              useJwt.setToken(response.data.access_token);
              useJwt.setRefreshToken(response.data.refreshToken);

              if (userData.role == 'admin') {
                userData.ability = [
                  {
                    action: 'manage',
                    subject: 'all',
                  },
                ];
              } else {
                userData.ability = [
                  {
                    action: 'manage',
                    subject: 'auth',
                  },
                ];
              }



              localStorage.setItem("userData", JSON.stringify(userData));
              this.$ability.update(userData.ability);

              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: this.$i18n.t(`Welcome`) + " " + userData.fullName || userData.username,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: this.$i18n.t(`You have successfully logged in. Now you can start to explore!`),
                    },
                  });
                });
            })
            .catch((error) => {
              if (error.response.status == 422) {
                this.validation = this.$t(error.response.data.error);
                this.validate = true
              } else {
                this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                  title: this.$i18n.t(error.response.data.message),
                  variant: "danger",
                  toaster: "b-toaster-bottom-right",
                });
              }
            });
        }
      });
    },
    getRegistrationIsEnabled() {
      console.debug(process.env.VUE_APP_ENABLE_REGISTER)
      return process.env.VUE_APP_ENABLE_REGISTER;
    }
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.logo-login {
  width: 100%;
  img {
    width: 160px;
  }
}
</style>
